<template>
  <div id="login">
    <full-loader v-show="loading"></full-loader>
    <!-- logo -->
    <div class="auth-logo">
      <img width="80" src="@/assets/v-logo.png" alt="GRID ADMIN PANEL" />
      <p style="color: white;">This is GRID information. Any modification, copying, reproduction, republication, uploading, posting, transmission, or distribution, in any manner, of the said information on the website, including text, graphics, code and/or software without permission in writing is prohibitted.</p>
    </div>

    <!-- login form -->
    <div class="login-card">
      <h3 class="title1">GRID Admin Panel</h3>
      <v-form ref="form" v-model="valid">
        <v-row>
          <!-- username / email id -->
          <v-col cols="12" sm="12">
            <!-- <label for="first name">Email Address:</label>
            <v-input type="text" required></v-input> -->
            <v-text-field
              label="Email Address"
              prepend-inner-icon="mdi-account"
              outlined
              autofocus
              color="appcolor"
              v-model="form.email_address"
              :rules="rules.email_address"
            ></v-text-field>
          </v-col>
          <!-- password -->
          <v-col cols="12" sm="12">
            <v-text-field
              label="Password"
              prepend-inner-icon="mdi-lock"
              :append-icon="show_password ? 'mdi-visibility' : 'mdi-visibility_off'"
              :type="show_password ? 'text' : 'password'"
              outlined
              @click:append="show_password = !show_password"
              color="appcolor"
              v-model="form.password"
              :rules="rules.password"
            ></v-text-field>
          </v-col>
          <!-- submit button -->
          <v-col cols="12" sm="12" style="text-align: center">
            <v-btn
              type="submit"
              :disabled="!valid"
              class="mr-4"
              style="background: #1261b3; color: #fff;"
              @click.prevent="login"
            >LOGIN</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import Mixins from "@/mixin";
import axios from "axios";
import FullLoader from "../../src/components/full-loader.vue";

export default {
  name: "login",
  mixins: [Mixins],
  components: {
    "full-loader": FullLoader,
  },
  data() {
    return {
      valid: true,
      show_password: false,

      // login form
      form: {
        email_address: "",
        password: ""
      },

      // validation rules
      rules: {
        email_address: [this.required("Email Address"), this.email()],
        password: [this.required("Password")]
      },
      loading: false
    };
  },
  methods: {
    // login submit
    async login() {
      try {
        // validate form
        if (!this.$refs.form.validate()) return;
         this.loading = true;
        // get api response
        let authResponse = await axios.post(`${config.baseAPIDomain}/auth`, {
          email: btoa(this.form.email_address),
          password: btoa(this.form.password),
          dept: btoa("Admin")
        });
        // check for error
        if (authResponse.data.errors){
           this.isLoading = false;
           throw authResponse.data.errors;
        }
     
       console.log("login ",authResponse.data.user);
        // save token and user data in store
        // this.$store.dispatch("SET_AUTH_DATA", {
        //   token: authResponse.data.token,
        //   user: JSON.stringify(authResponse.data.user)
        // });

        // save token and user data in localstorage
        localStorage.setItem("token", authResponse.data.token);
        localStorage.setItem(
          "user",
          btoa(JSON.stringify(authResponse.data.user))
        );
        localStorage.setItem(
          "userData",
          JSON.stringify(authResponse.data.user)
        );
        localStorage.setItem("sessionActive", true);
        this.loading = false;
        // router to user management after successfull login
        this.$router.push({ name: "tnb-logs" });
      } catch (err) {
        this.loading = false;
        this.alert("error", err, "");
      }
    }
  },
  async mounted() {
    await localStorage.clear();
  }
};
</script>

<style>
#login {
  height: 100vh;
  width: 100vw;
  background: url("../assets/login-background-image.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}
#login .auth-logo {
  position: absolute;
  width: 30%;
  text-align: justify;
  margin-left: 15px;
  font-size: 10px;
}

#login .login-card {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#login .login-card {
  top: 35%;
  background-color: transparent;
  min-width: 300px;
  width: 30vw;
  min-height: 300px;
  height: 40vh;
  padding: 20px 50px;
  border-radius: 1.5em;
}

#login .title1 {
    color: white;
    text-align: center;
    margin: 30px auto 25px auto;
}

.title1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
}

</style>